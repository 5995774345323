@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("./App.css");

:root {
  --primary-color-900: #6100ff;
  --primary-color-hover: #8439ff;
  --primary-color-pressed: #5600e3;
  --primary-color-disabled: #b8b5bc;
  --secondary-color: #ede3ff;
  --secondary-color-hover: #e4d4ff;
  --secondary-color-pressed: #d8c1ff;
  --secondary-color-disabled: #e5e2e8;
  --primary-color-600: #9857ff;
  --primary-color-550: #a066fe;
  --primary-color-500: #aa77ff;
  --primary-color-450: #b789ff;
  --primary-color-400: #c49dff;
  --primary-color-350: #cfb2ff;
  --primary-color-300: #dbc5ff;
  --primary-color-250: #e7d9ff;
  --primary-color-200: #f3eaff;
  --primary-color-150: #f9f5ff;
  --danger-color: #ff2f01;
  --danger-color-light: #fdf0f0;
  --warning-color: #ff5c00;
  --warning-color-light: #fef2eb;
  --success-color: #11d118;
  --success-color-light: #e8f6db;
  --white: #ffffff;
  --grayscale-color-100: #f4f6f6;
  --grayscale-color-200: #eaedee;
  --grayscale-color-300: #c8cbcd;
  --grayscale-color-400: #96999c;
  --grayscale-color-500: #56585a;
  --grayscale-color-600: #3e454d;
  --grayscale-color-700: #2b3340;
  --grayscale-color-800: #1b2434;
  --grayscale-color-900: #151515;
  --black: #000000;
  --mobile-header-height: 46px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", serif;
}

.overflowhidecs {
  height: 100vh !important;
  overflow-y: hidden !important;
}

.printPortal {
  display: none;
}

.amo-button-holder {
  display: none;
}

.amo-show {
  display: block !important;
  position: fixed;
  z-index: 1;
  right: 20px !important;
  bottom: 80px !important;
}

@media print and (color) {
  .printPortal {
    padding: 0 5%;
    display: block !important;
  }

  .template {
    background-color: white !important;
  }

  .question {
    page-break-inside: avoid;
  }

  .App :not(.printPortal) {
    display: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
